export function ShowModal(modalId) {
    const modalDiv = document.getElementById(modalId);
    const closeButton = modalDiv.getElementsByClassName('close')[0];
    closeButton.onclick = function () {
        modalDiv.style.display = 'none';
    };
    modalDiv.style.display = 'block';
    window.onclick = function (event) {
        if (event.target == modalDiv) {
            modalDiv.style.display = "none";
        }
    };
}
