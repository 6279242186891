import dayjs from 'dayjs';
import { NewWebsocket } from './common/mywebsocket';
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { GetInitialLanguage, UpdateLanguage } from './common/i18n';
import { ShowModal } from './common/modal';
import { MakeExactRegexPattern } from './common/utilities';
import { StartPingTimer } from './common/ping';
import { RandomString } from './common/clientid';
let datatable = null;
let webSocket = null;
let currentLanguage = GetInitialLanguage();
window.onload = function () {
    ReCreateDataTable();
    StartListeners();
    document.getElementById('language-en-us').onclick = function () {
        currentLanguage = UpdateLanguage('en-US', false);
        ReCreateDataTable();
    };
    document.getElementById('language-nb-no').onclick = function () {
        currentLanguage = UpdateLanguage('nb-NO', false);
        ReCreateDataTable();
    };
    document.getElementById('more-info').onclick = function () {
        ShowModal('more-info-modal');
    };
    currentLanguage = UpdateLanguage(currentLanguage.code, true);
    StartPingTimer('index.html', function () {
        return {
            'language': currentLanguage.code
        };
    });
};
// Caller must make sure that datatable.draw() is called.
function AddTodayMarking() {
    let data = datatable.data().toArray();
    let hasActiveEvent = false;
    // Remove any existing marker that may exists.
    for (const event of data) {
        if (event.is_today_marker) {
            datatable.row('#' + event.event_id).remove();
        }
    }
    // Find if we have any active event. If not, add a marker for today.
    for (const event of data) {
        if (IsActiveEvent(event) && !event.is_today_marker && event.public) {
            hasActiveEvent = true;
            break;
        }
    }
    if (!hasActiveEvent) {
        const todayMarker = {
            description: currentLanguage.no_event_today,
            event_id: RandomString(20),
            first_date: dayjs().format('YYYY-MM-DD'),
            last_date: dayjs().format('YYYY-MM-DD'),
            is_today_marker: true,
            organizer: '',
            public: true,
            race_type: ''
        };
        datatable.row.add(todayMarker);
    }
}
function StartListeners() {
    webSocket = new NewWebsocket('events', ['events']);
    webSocket.DataReceived = function (collectionName, data, fullReload) {
        if (collectionName != 'events') {
            return;
        }
        const changes = data;
        if (fullReload) {
            datatable.clear();
        }
        if (changes.delete != null) {
            for (const row of changes.delete) {
                const id = '#' + row[0];
                datatable.row(id).remove();
            }
        }
        if (changes.upsert != null) {
            for (const row of changes.upsert) {
                const id = '#' + row.event_id;
                const tableRow = datatable.row(id);
                if (tableRow.length == 0) {
                    datatable.row.add(row);
                }
                else {
                    tableRow.data(row);
                }
            }
        }
        AddTodayMarking();
        datatable.draw('full-hold');
    };
}
function ReCreateDataTable() {
    var data = [];
    if (datatable !== null) {
        data = datatable.data().toArray();
        datatable.clear();
        datatable.destroy();
    }
    document.getElementById('index-table').innerHTML = '';
    let columns = [
        {
            'data': 'public',
            'visible': false
        },
        {
            'data': 'description',
            'title': currentLanguage.index_race_description,
            'render': UrlRender
        },
        {
            'data': 'race_type',
            'title': currentLanguage.index_race_type,
            'render': UrlRender
        },
        {
            'data': 'organizer',
            'title': currentLanguage.index_race_organizer,
            'render': UrlRender
        },
        {
            'data': null,
            'title': currentLanguage.index_race_date,
            'type': 'num',
            'render': EventDateRender
        },
    ];
    var search = [];
    for (let i = 0; i < columns.length; ++i) {
        search.push(null);
    }
    search[0] = {
        'search': MakeExactRegexPattern('true'),
        'regex': true,
        'smart': false
    };
    datatable = new DataTable('#index-table', {
        'columns': columns,
        'paging': false,
        'dom': 't',
        'data': data,
        'createdRow': RowCreated,
        'order': [[columns.length - 1, 'desc']],
        'searchCols': search,
        'autoWidth': false,
        'rowId': 'event_id',
    });
    AddTodayMarking();
    datatable.draw('full-hold');
}
function UrlRender(data, _type, row, _meta) {
    if (row.is_today_marker) {
        return '<i>' + data + '</i>';
    }
    return '<a href="event.html?event_id=' + row.event_id + '">' + data + '</a>';
}
function EventDateRender(data, type, row, meta) {
    const firstDate = dayjs(data.first_date);
    const lastDate = dayjs(data.last_date);
    if (type == 'sort') {
        return firstDate.unix();
    }
    if (row.is_today_marker) {
        return '';
    }
    let output = '';
    if (firstDate.isSame(lastDate)) {
        output = firstDate.format('DD.MM.YYYY');
    }
    else if (firstDate.year() != lastDate.year()) {
        output = firstDate.format('DD.MM.YYYY') + '-' + lastDate.format('DD.MM.YYYY');
    }
    else if (firstDate.month() != lastDate.month()) {
        output = firstDate.format('DD.MM') + '-' + lastDate.format('DD.MM.YYYY');
    }
    else {
        output = firstDate.format('DD.') + '-' + lastDate.format('DD.MM.YYYY');
    }
    return UrlRender(output, type, row, meta);
}
function IsSameOrAfter(a, b) {
    return a.isSame(b) || a.isAfter(b);
}
function IsSameOrBefore(a, b) {
    return a.isSame(b) || a.isBefore(b);
}
function RowCreated(row, data, _dataIndex) {
    if (IsActiveEvent(data)) {
        row.classList.add('active-event');
    }
}
function IsActiveEvent(event) {
    const today = dayjs(dayjs().format('YYYY-MM-DD'));
    const firstDate = dayjs(event.first_date);
    const lastDate = dayjs(event.last_date);
    return IsSameOrAfter(today, firstDate) && IsSameOrBefore(today, lastDate);
}
